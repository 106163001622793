import React, { useContext, useEffect, useState } from 'react';
import { ConfiguratoreContext } from '../../store/configuratore-context';
import Switch from "react-switch";
import CanottoSelectorMobile from './CanottoSelectorMobile';
import StacchiSelector from './StacchiSelector';

function SelezioneStacchi({ numberOfZones, codicePlenum, windowWidth }) {

    const configuratoreCtx = useContext(ConfiguratoreContext);

    useEffect(() => {
        //setto il context di default per malfunzionamente che se non camb il valore delle select non si salva tutti i valori
        //per il momento gli stacchi setto tutto a 1 e mi baso su numberofzones per capire quali considerare, se serve settare a 0 copiare logica da schemaDistribuzioneMobile

        configuratoreCtx.setStacchiCtx({
            zona1: 1,
            zona2: 1,
            zona3: 1,
            zona4: 1,
            zona5: 1,
            zona6: 1,
        });
    }, [numberOfZones])

    return (
        <div style={{ marginTop: '30px' }}>
            <h3>SELEZIONA IL NUMERO DI STACCHI PER LOCALE</h3>
            <div style={windowWidth > 1500 ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } : null}>

                <div className='select-group' style={windowWidth < 1200 ? { flexDirection: 'column', justifyContent: 'flex-start' } : { justifyContent: 'flex-start' }}>
                    <StacchiSelector title="LOCALE 1" zoneIdentifier='zona1' isDisabled={numberOfZones == 6 ? true : false} numberOfZones={numberOfZones} />
                    <StacchiSelector title="LOCALE 2" zoneIdentifier='zona2' isDisabled={numberOfZones >= 5 ? true : false} numberOfZones={numberOfZones} />
                    {
                        numberOfZones > 2 ?
                            <StacchiSelector title="LOCALE 3" zoneIdentifier='zona3' isDisabled={numberOfZones >= 4 ? true : false} numberOfZones={numberOfZones} />
                            : null
                    }
                    {
                        numberOfZones > 3 ?
                            <StacchiSelector title="LOCALE 4" zoneIdentifier='zona4' isDisabled={true} numberOfZones={numberOfZones} />
                            : null
                    }
                    {
                        numberOfZones > 4 ?
                            <StacchiSelector title="LOCALE 5" zoneIdentifier='zona5' isDisabled={true} numberOfZones={numberOfZones} />
                            : null
                    }
                    {
                        numberOfZones > 5 ?
                            <StacchiSelector title="LOCALE 6" zoneIdentifier='zona6' isDisabled={true} numberOfZones={numberOfZones} />
                            : null
                    }
                </div>
                <div className="guida-utente" style={{maxWidth: '400px'}} >
                    <h3>GUIDA ALLA SELEZIONE DEGLI ELEMENTI</h3>
                    <p>
                        Il numero di locali identifica il numero di ambienti da trattare mentre il numero degli stacchi identifica il numero di terminali.
                    </p>
                    <p>
                        Se in un locale dovranno essere presenti due terminali, aggiungi gli stacchi necessari nel locale interessato.
                    </p>
                    <p>
                        Le selezioni successive terranno conto automaticamente di queste impostazioni.
                    </p>
                    <p>
                        Per ulteriori dettagli e opzioni avanzate consulta la nostra documentazione o contatta l’ufficio tecnico.
                    </p>
                    <p>
                        Nota: Il diametro degli stacchi viene calcolato in funzione del numero di locali e della portata d’aria massima della macchina.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SelezioneStacchi;