import React, { useState, useEffect, useRef, useContext, memo } from 'react';
import Select from 'react-select';
import './Configurator.css'; // Importa il file CSS per lo stile
import logo from './images/aircontrol_logo2024_white.png';

import sistemaCanalizzatoImg from './images/SISTEMA CANALIZZATO.jpg';
import sistemaMistoImg from './images/SISTEMA IMPIANTO MISTO.jpg';
import sistemaRadianteImg from './images/SISTEMA SOLO RADIANTE.jpg';
import distribuzioneImg from './images/DISTRIBUZIONE ARIA.jpg';
import motorizzatiImg from './images/ELEMENTI MOTORIZZATI.jpg';
import motorizzatiDistribuzioneImg from './images/ELEMENTI MOTORIZZATI E DISTRIBUZIONE ARIA.jpg';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Modal from 'react-modal';
import Switch from "react-switch";
import MyLoader from './components/MyLoader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReCAPTCHA from 'react-google-recaptcha';
import ConfiguratoreContextProvider, { ConfiguratoreContext } from './store/configuratore-context';
import ProgressBar from './components/ProgressBar';
//icone
import { BsArrowRightShort, BsArrowLeftShort, BsCheckLg } from "react-icons/bs";
import { IoArrowBack, IoArrowBackCircle, IoArrowForwardCircle, IoCheckmarkCircle, IoCheckmarkCircleOutline, IoClose, IoCloseCircle, IoCloseCircleOutline, IoCloseCircleSharp, IoMail, IoSnow, IoWarning } from "react-icons/io5";
import { FaLeaf, FaLightbulb, FaMobile, FaThermometer, FaThermometerThreeQuarters } from "react-icons/fa";
import SelezioneStacchi from './components/schema/SelezioneStacchi';
import Canvas from './components/canvas/Canvas';

const Configurator = () => {

    const canvasRef = useRef(null);

    //provvisorio per visualizzare portate kit
    const [kitProvvisorio, setKitProvvisorio] = useState('');

    //generali
    const [step, setStep] = useState(0); // Aggiunto lo stato per gestire i passi del configuratore
    const [totalSteps, setTotaleSteps] = useState(7);

    //costanti step per comodità
    const totalStepDis = 6;
    const totalStepMot = 9;
    const totalStepRad = 4;

    const [modalError, setModalError] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalConferma, setModalConferma] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [modalConf2, setModalConf2] = useState({
        flgModal: false,
        titolo: '',
        testo: '',
    });
    const [flgConf2, setFlgConf2] = useState(false); //flag che è true quando sono nella seconda configurazione
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const pathImmagini = "./images/immagini-articoli/";
    const [isLoading, setIsLoading] = useState(true);
    const [flgKitCompleto, setFlgKitCompleto] = useState(false); //flag che è true quando sono nella seconda configurazione
    //context
    const configuratoreCtx = useContext(ConfiguratoreContext);

    //valori selezionati
    const [tipoSistema, setTipoSistema] = useState('');
    const [kitCategory, setKitCategory] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [productModel, setProductModel] = useState('');
    const [diametroStacchi, setDiametroStacchi] = useState('');
    const [numberOfZones, setNumberOfZones] = useState(2);
    const [numTotaleStacchi, setNumTotaleStacchi] = useState(0);
    const [esteticaTerminale, setEsteticaTerminale] = useState('');
    const [tipoImpianto, setTipoImpianto] = useState('');
    const [coloreComandi, setColoreComandi] = useState('');
    const [tipoInterfaccia, setTipoInterfaccia] = useState('');
    const [stringaPathInterfaccia, setStringaPathInterfaccia] = useState('');
    const [codiceInterfaccia, setCodiceInterfaccia] = useState('');
    const [codicePlenum, setCodicePlenum] = useState('');
    const [flgPCAB, setFlgPCAB] = useState(0); //flag plenum cablato --> 0:NON SELEZIONATO; 1=SI; 2=NO
    const [flgPavimentoRadiante, setFlgPavimentoRadiante] = useState(false);
    const [numLocaliPavRadiante, setNumLocaliPavRadiante] = useState(2);
    const [flgKitSani, setFlgKitSani] = useState(false);

    //select marca e modello
    const [brandOptions, setBrandOptions] = useState([]);
    const [flgModelEnabled, setFlgModelEnabled] = useState(false);
    const [allModelOptions, setAllModelOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);

    //campi form di contatto
    const [ragioneSociale, setRagioneSociale] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [regione, setRegione] = useState(null);
    const [provincia, setProvincia] = useState('');
    const [ragioneSocialeError, setRagioneSocialeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [telefonoError, setTelefonoError] = useState(false);
    const [regioneError, setRegioneError] = useState(false);
    const [provinciaError, setProvinciaError] = useState(false);
    const [flgPrivacy, setFlgPrivacy] = useState(false);
    //captcha form
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const handleCaptchaChange = (value) => {
        // Verifica se il captcha è stato verificato
        if (value) {
            setIsCaptchaVerified(true);
        } else {
            setIsCaptchaVerified(false);
        }
    };

    //select province regioni
    const [regioniOptions, setRegioniOptions] = useState([]);
    const [flgProvinceEnabled, setFlgProvinceEnabled] = useState(false);
    const [allProvinceOptions, setAllProvinceOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);

    //elenco portate dei KIT lette da DB
    const [portateKit, setPortateKit] = useState([]);
    const [alertPortataTerminale, setAlertPortataTerminale] = useState(false);

    //codici KIT
    const [codiciKIT, setCodiciKIT] = useState([]);

    //handle steps
    const handleNextStep = () => {
        setIsLoading(true);
        switch (step) {
            case 0:
                //TIPO DI SISTEMA
                if (tipoSistema == '') {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare un tipo di sistema per procedere.' });
                    setIsLoading(false);
                    return;
                } else {
                    if (tipoSistema == 'radiante') {
                        setFlgPavimentoRadiante(true);
                        setProductBrand({ value: 'NON DEFINITA', label: 'NON DEFINITA' });
                        setProductModel({ value: 'NON DEFINITO', label: 'NON DEFINITO', plenum: 'NON DEFINITO', interfacciaFilo: '-', interfacciaIR: '-', interfacciaIdro: "-" });
                        setStep(3);
                        setTotaleSteps(totalStepRad);
                        setTimeout(function () {
                            setIsLoading(false);
                        }, 1000);
                        setKitCategory('kitRadiante');
                        return;
                    } else if (tipoSistema == 'misto') {
                        setFlgPavimentoRadiante(true);
                    }
                    break;
                }
            case 1:
                //CATEGORIA KIT
                if (kitCategory == '') {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare una categoria per procedere.' });
                    setIsLoading(false);
                    return;
                } else {
                    kitCategory == 'kitDistribuzione' ? setTotaleSteps(totalStepDis) : setTotaleSteps(totalStepMot);
                    break;
                }
            case 2:
                //MARCA E MODELLO
                if (productBrand == '' || productModel == '') {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare marca e modello per procedere.' });
                    setIsLoading(false);
                    return;
                } else break;
            case 3:
                //NUMERO DI LOCALI/STACCHI
                fetchPortateKit();
                let totStacchi = calcolaNumeroTotaleStacchi();
                setNumTotaleStacchi(totStacchi);
                if (flgKitCompleto) { //se è un kit completo motorizzato+distribuzione plenum per forza SI
                    configuratoreCtx.setFlgPlenumCtx(true);
                    setStep(5);
                    setTimeout(function () {
                        setIsLoading(false);
                    }, 750);
                    return;
                }
                //se marca modello NON DEFINITO devo impostare manualmente il codice di plenum
                if (productBrand.value == 'NON DEFINITA') {
                    //se MARCA 'NON DEFINITA' sono obbligato a selezionare la dimensioni canotti e il codice plenum è quello generico 
                    let CodicePlenumGenerico = '';
                    CodicePlenumGenerico = numberOfZones + "PLM-CDZ-" + diametroStacchi.value;
                    setCodicePlenum(CodicePlenumGenerico);
                }
                if (productBrand.value == 'NON DEFINITA' && diametroStacchi == '' && tipoSistema != 'radiante') {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare il diametro degli stacchi per procedere.' });
                    setIsLoading(false);
                    return;
                }
                if (numberOfZones == 0 || totStacchi == 0) {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare il numero di locali e di stacchi procedere.' });
                    setIsLoading(false);
                    return;
                } else break;
            case 4:
                //kitMotorizzato e kitDistibuzione: PLENUM SI/NO; radiante: COMANDI-->TERMINA
                if (kitCategory == 'kitMotorizzato' && !configuratoreCtx.flgPlenum) { //se seleziono plenum = NO allora non chiedo Plenum cablato si/no
                    setStep(6);
                    setTimeout(function () {
                        setIsLoading(false);
                    }, 750);
                    return;
                } else if (tipoSistema == 'radiante') {
                    if (tipoImpianto == '' || coloreComandi == '') {
                        setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare il KIT COMANDI per procedere.' });
                        setIsLoading(false);
                        return;
                    }
                    //SUBMIT ALLO STEP 5 SE --> sistema radiante 
                    handleSubmitConfiguratore();
                    setIsLoading(false);
                    return;
                } else break;
            case 5:
                //kitMotorizzato: PLENUM CABLATO; kitDistribuzione: ESTETICA TERMINALE-->SE Conf2 TERMINA;  ;
                if (kitCategory == 'kitMotorizzato' && flgPCAB == 0) { //flag plenum cablato per motorizzato
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Scegliere tra plenum cablato o plenum NON cablato.' });
                    setIsLoading(false);
                    return;
                } else if (kitCategory == 'kitDistribuzione' && esteticaTerminale == '') {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: "Selezionare l'estetica del terminale per procedere." });
                    setIsLoading(false);
                    return;
                } else if (kitCategory == 'kitDistribuzione' & flgConf2) {
                    handleSubmitConfiguratore();//SUBMIT ALLO STEP 5 SE --> kitDistribuzione ma in caso di flgConf2 (perchè KIT SANI l'ho già chiesto sul motorizzato)
                    setIsLoading(false);
                    return;
                } else break;
            case 6:
                //kitMotorizzato: KIT COMANDI; kitDistribuzione: KIT SANI-->TERMINA;
                if (kitCategory == 'kitMotorizzato' && (coloreComandi == '' || tipoImpianto == '')) {
                    setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare il KIT COMANDI per procedere.' });
                    setIsLoading(false);
                    return;
                } else {
                    if (kitCategory == 'kitDistribuzione') {
                        handleSubmitConfiguratore();
                        setIsLoading(false);
                        return;
                    }
                    if (kitCategory == 'kitMotorizzato') {
                        //preseleziono interfaccia a filo
                        if (productModel.interfacciaFilo != '') {
                            setTipoInterfaccia('FILO');
                        }
                    }
                    break;
                }
            case 7:
                //kitMotorizzato: TIPO INTERFACCIA;
                if (kitCategory == 'kitMotorizzato') {
                    if (tipoInterfaccia == '') {
                        setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: 'Selezionare il tipo di interfaccia per procedere.' });
                        setIsLoading(false);
                        return;
                    }
                    if (kitCategory == 'kitMotorizzato' && flgConf2) {//SUBMIT ALLO STEP 7 SE --> KitMotorizzato ma in caso di flgConf2
                        //perchè KIT SANI l'ho già chiesto sul distribuzione e pavimento radiante non si può mettere se si parte da distribuzione
                        handleSubmitConfiguratore();
                        setIsLoading(false);
                        return;
                    } else break;
                } else break;
            case 8:
                //kitMotorizzato: PAVIMENTO RADIANTE-->SE Conf2 TERMINA;                
                break;
            case 9:
                //kitMotorizzato:KIT SANI-->TERMINA DEFINITIVAMENTE PERCHE' VUOL DIRE CHE NON E' CONF2;
                if (kitCategory == 'kitMotorizzato') {
                    handleSubmitConfiguratore();
                    setIsLoading(false);
                    return;
                } else return;
            case 100:
                //FORM
                handleSubmitForm();
                return;
            case 101:
                setStep(0);
        }
        setStep(step + 1);
        setTimeout(function () {
            setIsLoading(false);

            // Il codice che vuoi eseguire dopo l'attesa
        }, 750);
    };


    const handlePreviousStep = () => {
        // Rimuovi le scelte fatte nello step corrente
        setIsLoading(true);
        if (step === 0) {
            setTipoSistema('');
            return;
        } else if (step === 1) {
            //se torno indietro al tipo sistema tolgo nel caso il flag pav radiante di default
            setFlgPavimentoRadiante(false);
            setKitCategory('');
        } else if (step === 2) {
            setProductBrand('');
            setProductModel('');
            setFlgModelEnabled(false);
        } else if (step === 3) {
            setNumberOfZones(2);
            setNumTotaleStacchi(0);
            configuratoreCtx.setStacchiCtx({
                zona1: 1,
                zona2: 1,
                zona3: 1,
                zona4: 1,
                zona5: 1,
                zona6: 1,
            });
            if (tipoSistema == 'radiante') {
                setStep(0);
                setIsLoading(false);
                return;
            }
        } else if (step === 4) {
            setTipoImpianto('');
            setColoreComandi('');
        } else if (step === 5) {
            setAlertPortataTerminale(false);
            setEsteticaTerminale('');
        } else if (step === 6) {
            setTipoImpianto('');
            setColoreComandi('');
            if (!configuratoreCtx.flgPlenum) {
                setStep(4);
                setIsLoading(false);
                return;
            }
        } else if (step === 7) {
            setTipoInterfaccia('');
            setStringaPathInterfaccia('');
        } else if (step === 100) {
            if (tipoSistema == 'radiante') {
                setStep(totalStepRad);
                setIsLoading(false);
                return;
            } else {
                if (kitCategory == 'kitDistribuzione') {
                    if (flgConf2) {
                        setStep(totalStepDis + 1);
                        setIsLoading(false);
                    } else {
                        setStep(totalStepDis);
                        setIsLoading(false);
                    }
                } else if (kitCategory == 'kitMotorizzato') {
                    if (flgConf2) {
                        setStep(totalStepMot - 1);
                        setIsLoading(false);
                    } else {
                        setStep(totalStepMot);
                        setIsLoading(false);
                    }
                }
                return;
            }
        }
        setStep(step - 1);
        setTimeout(function () {
            setIsLoading(false);
            // Il codice che vuoi eseguire dopo l'attesa
        }, 500);
    };

    //caricamento dati necessari
    const fetchMarcheModelli = () => {
        var data = {
            'op': 'getMarcheModelli',
        };
        return (
            fetch('https://www.aircontrolconfigurator.com/server/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    // Chiudi la modal di caricamento
                    if (responseJson.status === false) {
                        // Gestisci eventuali errori
                    }
                    setBrandOptions(responseJson.marche);
                    setAllModelOptions(responseJson.modelli);
                    return;
                })
                .catch(error => {
                    console.log(error);
                    // Gestisci eventuali errori
                })
        );
    }

    const fetchProvinceRegioni = () => {
        var data = {
            'op': 'getProvinceRegioni',
        };
        return (
            fetch('https://www.aircontrolconfigurator.com/server/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    // Chiudi la modal di caricamento
                    if (responseJson.status === false) {
                        // Gestisci eventuali errori
                    }
                    setRegioniOptions(responseJson.regioni);
                    setAllProvinceOptions(responseJson.province);
                    return;
                })
                .catch(error => {
                    console.log(error);
                    // Gestisci eventuali errori
                })
        );
    }

    const fetchPortateKit = () => {
        var data = {
            'op': 'getPortateKit',
        };
        return (
            fetch('https://www.aircontrolconfigurator.com/server/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    // Chiudi la modal di caricamento
                    if (responseJson.status === false) {
                        // Gestisci eventuali errori
                    }
                    setPortateKit(responseJson.portateKit);
                    return;
                })
                .catch(error => {
                    console.log(error);
                    // Gestisci eventuali errori
                })
        );
    }

    //funzioni grafiche
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    const selectErrorStyle = {
        control: (base, state) => ({
            ...base,
            borderColor: "#fca8a8",
            background: "#fdeded",
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };

    useEffect(() => {

        setIsLoading(true);

        fetchMarcheModelli();
        fetchProvinceRegioni();

        window.addEventListener('resize', handleWindowResize);

        setTimeout(function () {
            setIsLoading(false);
        }, 1000);

        // Clean up: rimuovi l'event listener quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };


    }, []);

    const LazyImageMemo = memo(({ src, alt }) => {
        return <LazyLoadImage effect='blur' src={src} alt={alt} className="label-image" />;
    });

    //array delle opzioni menu a tendina
    const diametroStacchiOptions = [
        { value: '150', label: '150 mm' },
        { value: '200', label: '200 mm' },
    ];

    const numberOfZonesOptions = [
        { value: '2', label: '2 LOCALI', img: pathImmagini + '4zone.jpg' },
        { value: '3', label: '3 LOCALI', img: pathImmagini + '6zone.jpg' },
        { value: '4', label: '4 LOCALI', img: pathImmagini + '4zone.jpg' },
        { value: '5', label: '5 LOCALI', img: pathImmagini + '4zone.jpg' },
        { value: '6', label: '6 LOCALI', img: pathImmagini + '6zone.jpg' },
    ];

    const numberOfZonesPavRadianteOptions = [
        { value: '2', label: '2 LOCALI' },
        { value: '3', label: '3 LOCALI' },
        { value: '4', label: '4 LOCALI' },
        { value: '5', label: '5 LOCALI' },
        { value: '6', label: '6 LOCALI' },
        { value: '7', label: '7 LOCALI' },
        { value: '8', label: '8 LOCALI' },
    ];
    //handle dati selezionati
    const handleTipoSistemaChange = (value) => {
        setTipoSistema(value);
    };

    const handleKitCategoryChange = (value) => {
        //se è un kit completo lascio kitCategory = motorizzato e setto il flgKitCompleto a true
        if (value == 'kitCompleto') {
            value = 'kitMotorizzato';
            setFlgKitCompleto(true);
            configuratoreCtx.setFlgPlenumCtx(true);
        } else {
            setFlgKitCompleto(false);
        }
        setKitCategory(value);

        if (value == 'kitDistribuzione')
            configuratoreCtx.setFlgPlenumCtx(true);
    };

    const handleBrandChange = (selectedOption) => {
        setProductBrand(selectedOption);
        let newModelOptions = allModelOptions.filter(model => model.marca == selectedOption.value);
        setModelOptions(newModelOptions);
        setFlgModelEnabled(true);
        setProductModel('');
        if (selectedOption.value == 'NON DEFINITA') {
            setProductModel({ value: 'NON DEFINITO', label: 'NON DEFINITO', plenum: 'NON DEFINITO', interfacciaFilo: '-', interfacciaIR: '-', interfacciaIdro: '-' });
            setCodiceInterfaccia('NOINT');
            setFlgModelEnabled(false);
        }
    };

    const handleModelChange = (selectedOption) => {
        setProductModel(selectedOption);
        setCodiceInterfaccia(selectedOption.interfaccia);
        setCodicePlenum(selectedOption.plenum);
    };

    const handleDimCanottiChange = (selectedOption) => {
        setDiametroStacchi(selectedOption);
    };

    const handleNumberOfZonesChange = (selectedOption) => {
        setNumberOfZones(selectedOption.value);
        //setto anche le zone di default del pavimento radiante
        setNumLocaliPavRadiante(selectedOption.value);
    };

    const handleNumLocaliPavRadianteChange = (selectedOption) => {
        setNumLocaliPavRadiante(selectedOption.value);
    };

    const handleEsteticaTerminaleChange = (value) => {
        if (checkPortataTerminale(value) || productBrand.value == 'NON DEFINITA') {
            setAlertPortataTerminale(false);
        } else {
            setAlertPortataTerminale(true);
        }
        setEsteticaTerminale(value);
        checkPortataTerminaleProvvisorio(value);
    };

    const handleTipoImpiantoChange = (value) => {
        setTipoImpianto(value);
    };

    const handleColoreComandiChange = (value) => {
        setColoreComandi(value);
    };

    const handleComandiChange = (valueColore, valueImpianto) => {
        setColoreComandi(valueColore);
        setTipoImpianto(valueImpianto);
    };

    const handleTipoInterfacciaChange = (value) => {
        setTipoInterfaccia(value);
        value == 'INFRAROSSO' ?
            setStringaPathInterfaccia("_interfaccia_IR")
            : value == 'FILO' ?
                setStringaPathInterfaccia('_interfaccia_filo')
                : setStringaPathInterfaccia('_senza_interfaccia');
    };

    const handleChangePavRadiante = () => {
        let newFlg = !flgPavimentoRadiante;
        setFlgPavimentoRadiante(newFlg);
    };

    const handleRegioneChange = (selectedOption) => {
        setRegione(selectedOption);
        setRegioneError(false);
        let newProvinceOptions = allProvinceOptions.filter(province => province.id_regione == selectedOption.id);
        setProvinceOptions(newProvinceOptions);
        setFlgProvinceEnabled(true);
        setProvincia('');
    };

    const handleProvinciaChange = (selectedOption) => {
        setProvincia(selectedOption);
        setProvinciaError(false);
    };

    const handlePrivacyChange = (e) => {
        setFlgPrivacy(e.target.checked);
    };

    const handleFlgPlenumChange = () => {
        let newFlg = !configuratoreCtx.flgPlenum;
        configuratoreCtx.setFlgPlenumCtx(newFlg);
    };

    const handleFlgKitSaniChange = () => {
        let newFlg = !flgKitSani;
        setFlgKitSani(newFlg);
    };

    const handleFlgPCABChange = (flg) => {
        let newFlg = flg;
        setFlgPCAB(newFlg);
    };

    const checkPortataTerminaleProvvisorio = (terminalType) => {

        const availableSizes = ['150', '200'];
        let closestKit = null;
        let closestDifference = Infinity;
        let desKitProvvisorio = '';

        for (const size of availableSizes) {
            const kitCode = `${numTotaleStacchi}KITDIS-${terminalType}-${size}`;
            const kit = portateKit.find(kit => kit.CodArt === kitCode);
            desKitProvvisorio = desKitProvvisorio + " KIT " + size + "  : " + kit.CodArt + " - Portata: " + kit.Portata;


        }

        setKitProvvisorio(desKitProvvisorio);
    }

    //controllo portata kit in confronto a portata macchina
    const checkPortataTerminale = (terminalType) => {

        const availableSizes = ['150', '200'];
        let closestKit = null;
        let closestDifference = Infinity;

        for (const size of availableSizes) {
            const kitCode = `${numTotaleStacchi}KITDIS-${terminalType}-${size}`;
            const kit = portateKit.find(kit => kit.CodArt === kitCode);

            if (kit) {
                // Controllo sulla portata
                const kitPortata = kit.Portata;
                const portataMin = productModel.portataMax * 0.8;
                const portataMaxThreshold = productModel.portataMax * 1.2;

                if (kitPortata >= portataMin && kitPortata <= portataMaxThreshold) {
                    const difference = Math.abs(productModel.portataMax - kitPortata);
                    if (difference < closestDifference) {
                        closestKit = kit.CodArt;
                        closestDifference = difference;
                    }
                }
            }
        }


        return closestKit;
    }

    //controllo portata kit in confronto a portata macchina
    const determinaKitDistribuzione = (terminalType) => {

        //con modello macchina non definita ritorno il kit dimensione stacchi scelta
        if (productModel.value == 'NON DEFINITO') return `${numTotaleStacchi}KITDIS-${terminalType}-${diametroStacchi.value}`;

        const availableSizes = ['150', '200'];
        let closestKit = null;
        let closestDifference = Infinity;

        for (const size of availableSizes) {
            const kitCode = `${numTotaleStacchi}KITDIS-${terminalType}-${size}`;
            const kit = portateKit.find(kit => kit.CodArt === kitCode);

            if (kit) {
                // Controllo sulla portata
                const kitPortata = kit.Portata;
                const difference = Math.abs(productModel.portataMax - kitPortata);
                if (difference < closestDifference) {
                    closestKit = kit.CodArt;
                    closestDifference = difference;
                }
            }
        }

        return closestKit;
    }

    //DIMENSIONE SERRANDA ASSOCIATA AL KIT MOTORIZZATO PIU' VICINA POSSIBILE ALLA PORTATA MACCHINA
    const determinaDimensioneSerranda = (tipoKitMotorizzato) => {

        //con modello macchina non definita ritorno dimensione stacchi scelta
        if (productModel.value == 'NON DEFINITO') return diametroStacchi.value;

        const availableSizes = [150, 200];
        let closestKitSize = null;
        let closestDifference = Infinity;

        for (const size of availableSizes) {
            const kitCode = `${numTotaleStacchi}${tipoKitMotorizzato}-CDZ-${size}-CFI`;
            const kit = portateKit.find(kit => kit.CodArt === kitCode);
            if (kit) {
                const difference = Math.abs(productModel.portataMax - kit.Portata);
                if (difference < closestDifference) {
                    closestKitSize = size;
                    closestDifference = difference;
                }
            }
        }

        return closestKitSize;
    };

    //DETERMINAZIONE CODICE KIT
    const determinaCodiceKIT = () => {

        let codiceKIT = '';

        if (tipoSistema == 'radiante') {
            if (coloreComandi == 'bianchi' && tipoImpianto == 'RADIO') codiceKIT = numberOfZones + "COM-R-B";
            if (coloreComandi == 'bianchi' && tipoImpianto == 'FILO') codiceKIT = numberOfZones + "COM-F-B";
            if (coloreComandi == 'neri' && tipoImpianto == 'RADIO') codiceKIT = numberOfZones + "COM-R-N";
            if (coloreComandi == 'neri' && tipoImpianto == 'FILO') codiceKIT = numberOfZones + "COM-F-N";
        } else if (kitCategory == 'kitMotorizzato') {
            let codIntSelezionata = '';
            if (tipoInterfaccia == 'FILO') codIntSelezionata = 'CFI';
            else if (tipoInterfaccia == 'INFRAROSSO') codIntSelezionata = 'CIR';
            else if (tipoInterfaccia == 'FANCOIL') codIntSelezionata = 'GTP';
            else if (tipoInterfaccia == 'NO') codIntSelezionata = 'NOINT'; //VEDERE COME GESTIRE

            if (flgPCAB == 1) {
                codiceKIT = numTotaleStacchi + "PCAB-CDZ-" + determinaDimensioneSerranda("PCAB") + "-" + codIntSelezionata;
            } else {
                codiceKIT = numTotaleStacchi + "PSC-CDZ-" + determinaDimensioneSerranda("PSC") + "-" + codIntSelezionata;
            }
        } else if (kitCategory == 'kitDistribuzione') {
            codiceKIT = determinaKitDistribuzione(esteticaTerminale);
        }
        const kitEsistente = portateKit.some(kit => kit.CodArt === codiceKIT);

        if (kitEsistente) {
            return codiceKIT;
        } else {
            return false;
        }
    }

    const determinaCodiceKITComandi = () => {

        let codiceKIT = '';

        if (kitCategory == 'kitMotorizzato') {
            if (flgPavimentoRadiante) {
                let numLocaliKitComandi = numLocaliPavRadiante >= numberOfZones ? numLocaliPavRadiante : numberOfZones;
                if (coloreComandi == 'bianchi' && tipoImpianto == 'RADIO') codiceKIT = numLocaliKitComandi + "COM-R-B";
                if (coloreComandi == 'bianchi' && tipoImpianto == 'FILO') codiceKIT = numLocaliKitComandi + "COM-F-B";
                if (coloreComandi == 'neri' && tipoImpianto == 'RADIO') codiceKIT = numLocaliKitComandi + "COM-R-N";
                if (coloreComandi == 'neri' && tipoImpianto == 'FILO') codiceKIT = numLocaliKitComandi + "COM-F-N";
            } else {
                if (coloreComandi == 'bianchi' && tipoImpianto == 'RADIO') codiceKIT = numberOfZones + "COM-R-B";
                if (coloreComandi == 'bianchi' && tipoImpianto == 'FILO') codiceKIT = numberOfZones + "COM-F-B";
                if (coloreComandi == 'neri' && tipoImpianto == 'RADIO') codiceKIT = numberOfZones + "COM-R-N";
                if (coloreComandi == 'neri' && tipoImpianto == 'FILO') codiceKIT = numberOfZones + "COM-F-N";
            }
        }

        const kitEsistente = portateKit.some(kit => kit.CodArt === codiceKIT);

        if (kitEsistente) {
            return codiceKIT;
        } else {
            return false;
        }
    }

    const determinaCodiceKITSani = () => {

        let codiceKIT = '';

        if (flgKitSani && !flgConf2) { //SE è LA CONFIGURAZIONE 2 NON LO REINSERISCO PERCHè L'HO GIà MESSO
            if (productModel.portataMax > 1600) {
                codiceKIT = 'KIT-SANI-2';
            } else {
                codiceKIT = 'KIT-SANI-1';
            }
        }

        const kitEsistente = portateKit.some(kit => kit.CodArt === codiceKIT);

        if (kitEsistente) {
            return codiceKIT;
        } else {
            return false;
        }
    };

    const calcolaNumeroTotaleStacchi = () => {
        let totaleStacchi = 0;
        for (let i = 1; i <= numberOfZones; i++) {
            totaleStacchi += +configuratoreCtx.stacchi[`zona${i}`];
        }
        return totaleStacchi;
    };

    // Funzione per aggiungere nuovi codici KIT all'array
    const aggiungiCodiciKIT = (nuoviCodici) => {

        let newCodici = codiciKIT;
        for (let i = 0; i < nuoviCodici.length; i++) {
            newCodici.push(nuoviCodici[i]);
        }

        //se è la configurazione 2 controllo che i due KIT non abbiano dimensioni discordanti (150, 200)
        //nel caso siano discordanti porto il 150 a 200
        //due KIT sono discordanti se uno contiene la stringa '150' e l'altro contiene la stringa '200'
        if (flgConf2) {
            const has150 = newCodici.some(codice => codice.includes('-150'));
            const has200 = newCodici.some(codice => codice.includes('-200'));
            if (has150 && has200) {
                newCodici = newCodici.map(codice => codice.replace('-150', '-200'));
            }
        }

        setCodiciKIT(newCodici);
    };

    //submit
    const handleSubmitConfiguratore = () => {

        //determinazione del KIT principale
        let codiceKIT = determinaCodiceKIT();

        //determinazione del KIT comandi
        let codiceKITComandi = determinaCodiceKITComandi();

        //determinazione del KIT SANI --> solo se flgConf2 == false
        let codiceKITSani = determinaCodiceKITSani();

        if (codiceKIT == false) {
            setModalError({ flgModal: true, titolo: 'ERRORE', testo: "Errore nella determinazione del codice KIT." });
            return;
        }
        if (codiceKITComandi == false && kitCategory == 'kitMotorizzato') {
            setModalError({ flgModal: true, titolo: 'ERRORE', testo: "Errore nella determinazione del codice KIT COMANDI." });
            return;
        }


        if (codiceKITComandi && codiceKITSani) {
            aggiungiCodiciKIT([codiceKIT, codiceKITComandi, codiceKITSani]);
        } else if (codiceKITComandi) {
            aggiungiCodiciKIT([codiceKIT, codiceKITComandi]);
        } else if (codiceKITSani) {
            aggiungiCodiciKIT([codiceKIT, codiceKITSani]);
        } else {
            aggiungiCodiciKIT([codiceKIT]);
        }

        //vado al form
        setTimeout(function () {
            setIsLoading(false);
        }, 750);
        setStep(100);

        if (kitCategory == 'kitMotorizzato' && flgKitCompleto) {
            //se KIT COMPLETO vado diretto alla configurazione 2
            handleConfigurazione2();
        } else if (!flgConf2 && tipoSistema != 'radiante') {
            //altrimento chiedo la se vuole la configurazione 2
            setModalConf2({
                flgModal: true, titolo: 'CONFIGURAZIONE COMPLETATA',
                testo: ''
            });
        }

    };

    const handleConfigurazione2 = () => {
        //setto il flag e chiudo il modal
        setFlgConf2(true);
        setModalConf2({ flgModal: false, titolo: '', testo: '' })

        //salvo la categoria corrente
        let kitCategoryCorrente = kitCategory;

        //reset valori per configurazione impianto di tipo inverso
        if (kitCategory == 'kitMotorizzato') {
            setKitCategory('kitDistribuzione');
            setTotaleSteps(totalStepDis - 1);
        } else if (kitCategory == 'kitDistribuzione') {
            setKitCategory('kitMotorizzato');
            setTotaleSteps(totalStepMot - 2);
        } else return false;
        /* VEDERE COSA FARE PER PULIZIA VALORI %%%%%*/

        const scrollToElement = document.getElementById('app');
        if (scrollToElement) {
            scrollToElement.scrollIntoView({ behavior: 'smooth' });
        }

        if (kitCategoryCorrente == 'kitMotorizzato') {
            setStep(5);
        } else if (kitCategoryCorrente == 'kitDistribuzione') {
            setStep(5);
        } else return false;
    }

    const handleCloseConfigurazione2 = () => {
        setModalConf2({ flgModal: false, titolo: '', testo: '' });
    }

    const handleSubmitForm = (e) => {

        // Verifica se i campi obbligatori sono stati lasciati vuoti
        if (ragioneSociale == '' || email == '' || telefono == '' || regione == '' || provincia == '' || !flgPrivacy || !isCaptchaVerified) {
            // Imposta lo stato degli errori
            setRagioneSocialeError(ragioneSociale == '');
            setEmailError(email == '');
            setTelefonoError(telefono == '');
            setRegioneError(regione == '');
            setProvinciaError(provincia == '');

            if (!flgPrivacy) {
                setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: "Accettare l'informativa sulla privacy per proseguire." });
            }
            if (!isCaptchaVerified) {
                setModalError({ flgModal: true, titolo: 'DATI INCOMPLETI', testo: "Controllo reCaptcha non eseguito." });
            }
            // Interrompi l'invio del form
            setIsLoading(false);
            return false;
        }

        //salva preventivo e invia e-mail
        fetchSalvaPreventivo();

    };

    function updateAllFormError(flag) {
        setRagioneSocialeError(flag);
        setEmailError(flag);
        setTelefonoError(flag);
        setRegioneError(flag);
        setProvinciaError(flag);
    };

    //salva preventivo
    const fetchSalvaPreventivo = () => {

        let dataURL = '';
        if (kitCategory == 'kitMotorizzato' || flgConf2) {
            dataURL = canvasRef.current.exportCanvas();
        }
        setIsLoading(true);
        var data = {
            'op': 'salvaPreventivo',
            'RagSoc': ragioneSociale,
            'IndirEmail': email,
            'Telefono': telefono,
            'Regione': regione.value,
            'Provincia': provincia.value,
            'CodiceKIT': '',
            'CodiciKIT': codiciKIT,
            'FlgPavimentoRadiante': flgPavimentoRadiante ? 1 : 0,
            'FlgPlenum': configuratoreCtx.flgPlenum ? 1 : 0,
            'CodicePlenum': codicePlenum,
            'FlgPCAB': flgPCAB,
            'TipoSistema': tipoSistema,
            'kitCategory': kitCategory,
            'Marca': productBrand.value,
            'Modello': productModel.value,
            'elencoComponenti': [],
            'flgConf2': flgConf2 ? 1 : 0,
            'configurazione1': configuratoreCtx.configurazione1,
            'SchemaImpiantoRadiante': tipoSistema == 'radiante' ? `collegamento_${tipoImpianto}_${numberOfZones}zone_${numberOfZones}zone_impianto${tipoImpianto}_comandi_${coloreComandi}_solo_PAV-RADIANTE.jpg` : '',
            'canvasImage': dataURL // Add the Base64 image data here
        };
        return (
            fetch('https://www.aircontrolconfigurator.com/server/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === false) {
                        if (responseJson.errorMail == true) {
                            setModalError({ flgModal: true, titolo: 'ERRORE INVIO E-MAIL', testo: "Controllare l'indirizzo e-mail inserito" });
                        } else {
                            setModalError({ flgModal: true, titolo: 'ERRORE', testo: 'Errore in fase di inserimento preventivo.' });
                        }
                    } else {
                        setModalConferma({ flgModal: true, titolo: 'PREVENTIVO INVIATO', testo: "Riceverai il PDF del preventivo all'indirizzo e-mail specificato." });
                    }
                    setIsLoading(false);
                    return;
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                    // Gestisci eventuali errori
                })
        );
    }

    const closeModalConferma = () => {
        window.location.reload(); // Esegui il refresh della pagina
    };

    const geolocalizzazione = () => {
        /*if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    // Ora puoi utilizzare la latitudine e la longitudine per ottenere informazioni sulla posizione
                    // Ad esempio, puoi utilizzare servizi di geocodifica come OpenCage o Google Maps API
                    // per ottenere informazioni dettagliate sulla posizione come il paese, la regione e la provincia.

                    // Esempio di utilizzo di OpenCage Geocoding API
                    const apiKey = '7591573efe5d4e358ea82d59471c30c7'; // Sostituisci con la tua chiave API OpenCage
                    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?key=${apiKey}&q=${latitude}+${longitude}&pretty=1`;

                    fetch(apiUrl)
                        .then(response => response.json())
                        .then(data => {
                            const results = data.results;
                            if (results.length > 0) {
                                const country = results[0].components.country;
                                const region = results[0].components.state; // Potrebbe variare a seconda del paese
                                const province = results[0].components.state_district;

                                console.log(`Paese: ${country}, Regione: ${region}, Provincia: ${province}`);
                            }
                        })
                        .catch(error => {
                            console.error('Errore durante il recupero delle informazioni sulla posizione:', error);
                        });
                },
                (error) => {
                    console.error('Errore durante la geolocalizzazione:', error);
                }
            );
        } else {
            console.error('La geolocalizzazione non è supportata dal tuo browser.');
        }*/
    }

    const scrollableElementRef = useRef(null);
    useEffect(() => {
        /*
        if (scrollableElementRef.current) {
          scrollableElementRef.current.scrollTo(0, 0);
        }*/
        const scrollToElement = document.getElementById('app');
        if (scrollToElement) {
            scrollToElement.scrollIntoView({ behavior: 'smooth' });
        }
        //se mi sposto tolgo gli error dal form di contatto
        updateAllFormError(false);
    }, [step]);

    //COMPONENTI GRAFICI DELLE SCELTE
    const PlenumSiNoChoice = ({ }) => {
        return (
            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h3>SELEZIONE PLENUM</h3>
                <div className='switch-group' style={{ marginTop: '0px' }}>
                    <div className='switch-option' onClick={handleFlgPlenumChange}>
                        <img
                            effect='blur'
                            src={require(`../src/images/immagini-articoli/plenum_${numTotaleStacchi}zone.png`)}
                            alt="PLENUM"
                            className="label-image"
                        />
                        <div className="label-container" style={{ marginTop: '10px' }}>
                            <label className="label-text-big">
                                {
                                    kitCategory == 'kitDistribuzione' ?
                                        "VUOI INCLUDERE IL PLENUM DI MANDATA NEGLI ELEMENTI DI DISTRIBUZIONE AERAULICA?"
                                        : "VUOI INCLUDERE IL PLENUM DI MANDATA NEGLI ELEMENTI MOTORIZZATI?"
                                }
                            </label>
                        </div>
                        <Switch onChange={handleFlgPlenumChange} checked={configuratoreCtx.flgPlenum} className='switch'
                            offColor='#a6a6a6' onColor='#0054a0'
                        />
                        {
                            configuratoreCtx.flgPlenum &&
                            <div className='label-container-flow' style={{ width: '100%', maxWidth: '250px' }}>
                                <label className='label-flow'>CODICE</label>
                                <label className='title-flow'>
                                    {codicePlenum}
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    const TipoImpiantoChoice = ({ systemType }) => {
        return (
            <div className={tipoImpianto === systemType ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                onClick={handleTipoImpiantoChange.bind(this, systemType)}>
                <div className='option-image-container'>
                    <img
                        effect='blur'
                        src={require(pathImmagini + "centralina_" + systemType.toLowerCase() + ".jpg")}
                        alt="Impianto"
                        className="label-image-piena"
                    />
                </div>
                <div className="label-container">
                    <label className="title-label">
                        <input
                            type="radio"
                            name="tipoImpianto"
                            value={systemType}
                            checked={tipoImpianto === systemType}
                            onChange={handleTipoImpiantoChange.bind(this, systemType)}
                            className="input-radio"
                        />
                        Impianto {systemType.toLowerCase()}</label>
                </div>
            </div>
        );
    }

    const ColoreComandiChoice = ({ commandColor }) => {
        return (
            <div className={coloreComandi === commandColor ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                onClick={handleColoreComandiChange.bind(this, commandColor)}>
                <div className='option-image-container'>
                    <img
                        effect='blur'
                        src={require(pathImmagini + "K" + commandColor.substring(0, 1).toUpperCase() + "_ETERNAL-SMART.jpg")}
                        alt={commandColor}
                        className="label-image"
                    />
                </div>
                <div className="label-container">
                    <label className="title-label">
                        <input
                            type="radio"
                            name="coloreComandi"
                            value={commandColor}
                            checked={coloreComandi === commandColor}
                            onChange={handleColoreComandiChange.bind(this, commandColor)}
                            className="input-radio"
                        />{commandColor == 'bianchi' ? 'Bianco' : 'Nero'}</label>
                </div>
            </div>
        );
    }

    const ComandiChoice = ({ commandColor, impiantoType }) => {
        return (
            <div className={coloreComandi === commandColor && tipoImpianto == impiantoType ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                onClick={handleComandiChange.bind(this, commandColor, impiantoType)}>
                <div className='option-image-container'>
                    <img
                        effect='blur'
                        src={require(pathImmagini + "K" + commandColor.substring(0, 1).toUpperCase() + "_ETERNAL-SMART.jpg")}
                        alt={commandColor}
                        className="label-image"
                    />
                </div>
                <div className="label-container">
                    <label className="title-label">
                        <input
                            type="radio"
                            name="comandi"
                            value={commandColor + " - " + impiantoType}
                            checked={coloreComandi === commandColor && tipoImpianto == impiantoType}
                            onChange={handleComandiChange.bind(this, commandColor, impiantoType)}
                            className="input-radio"
                        />{impiantoType} {commandColor.toUpperCase().replace('BIANCHI', 'BIANCO').replace('NERI', 'NERO')}</label>
                </div>
            </div>
        );
    }

    const TerminalSelection = ({ }) => {

        const terminalTypes = ["BMA", "DLF", "PSB", "PSD", "PSF"];

        // Filtra e ordina i terminali -- MECCANISMO CHE METTE PER PRIMI I CONSIGLIATI RIMOSSO
        /*const recommendedTerminals = terminalTypes.filter(type => checkPortataTerminale(type));
        const nonRecommendedTerminals = terminalTypes.filter(type => !checkPortataTerminale(type));*/
        const recommendedTerminals = ["PSB", "PSD", "PSF"];
        const nonRecommendedTerminals = ["BMA", "DLF"];
        const sortedTerminals = [...recommendedTerminals, ...nonRecommendedTerminals];

        // Divide i terminali in due gruppi
        const firstGroup = sortedTerminals.slice(0, 3);
        const secondGroup = sortedTerminals.slice(3);

        return (
            <div>
                <div className='guida-utente' style={{ textAlign: 'center' }}>
                    TERMINALI CON STACCO POSTERIORE per eventuali modifiche alla posizione dello stacco, segnalare in fase d’ordine
                </div>
                <div className='radio-group'>
                    {firstGroup.map((terminalType, index) => (
                        <TerminalChoice
                            key={index}
                            terminalType={terminalType}
                        />
                    ))}
                </div>
                <div className='guida-utente' style={{ textAlign: 'center' }}>
                    Per l’installazione a muro, segnalare in fase d’ ordine. NB: codici e prezzi degli articoli potrebbero essere soggetti a variazioni.
                </div>
                <div className='radio-group'>
                    {secondGroup.map((terminalType, index) => (
                        <TerminalChoice
                            key={index + firstGroup.length}
                            terminalType={terminalType}
                        />
                    ))}
                </div>
                {
                    false &&
                    <>
                        <div>Portata Macchina : {productModel.portataMax}</div>
                        <div>{kitProvvisorio}</div>
                    </>
                }
            </div>
        )
    };

    const TerminalChoice = ({ terminalType }) => {
        return (
            <div className={esteticaTerminale === terminalType ? 'radio-option elevation-2' : 'radio-option'} onClick={handleEsteticaTerminaleChange.bind(this, terminalType)}>
                <div className='option-image-container'>
                    <img
                        effect='blur'
                        src={require(pathImmagini + terminalType + ".jpg")}
                        alt={terminalType}
                        className="label-image"
                    />
                </div>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    {
                        false && //DISATTIVATO BADGE SCELTA CONSIGLIATA
                        checkPortataTerminale(terminalType) && terminalType != "BMA" && terminalType != "DLF" && //SU TERMINALI NON DI PRODUZIONE INTERNA MAI SCELTA CONSIGLIATA
                        <div className='recommended-choice'>
                            <IoCheckmarkCircle className="check-icon" />
                            Scelta consigliata
                        </div>
                    }
                </div>
                <div className="label-container">
                    <label className="title-label">
                        <input
                            type="radio"
                            name="esteticaTerminale"
                            value={terminalType}
                            checked={esteticaTerminale === terminalType}
                            onChange={handleEsteticaTerminaleChange.bind(this, terminalType)}
                            className="input-radio"
                        />{terminalType}</label>
                </div>
                <div className='label-text'>
                    {
                        terminalType == 'PSB' ? 'Plenum bocchetta a scomparsa'
                            : terminalType == 'PSD' ? 'Plenum diffusore a scomparsa'
                                : terminalType == 'PSF' ? 'Plenum feritoia a scomparsa'
                                    : terminalType == 'BMA' ? 'Bocchetta a doppia deflessione'
                                        : terminalType == 'DLF' ? 'Diffusore lineare'
                                            : null
                    }
                </div>

            </div>
        );
    }

    const TipoInterfacciaChoice = ({ interfaceType, interfaceCode, interfaceDescription }) => {
        return (
            <div className={tipoInterfaccia === interfaceType ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                onClick={handleTipoInterfacciaChange.bind(this, interfaceType)} style={{ marginTop: '0px' }}>
                <div className='option-image-container'>
                    <img
                        effect='blur'
                        src={require(pathImmagini + interfaceType + ".jpg")}
                        alt={interfaceType}
                        className="label-image-piena"
                    />
                </div>
                {
                    interfaceType == 'FILO' &&
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <div className='recommended-choice'>
                            <IoCheckmarkCircle className="check-icon" />
                            Scelta consigliata
                        </div>
                    </div>
                }
                <div className='option-text-container'>
                    <div className="label-container">
                        <label className="title-label">
                            <input
                                type="radio"
                                name="tipoInterfaccia"
                                value={interfaceType}
                                checked={tipoInterfaccia === interfaceType}
                                onChange={handleTipoInterfacciaChange.bind(this, interfaceType)}
                                className="input-radio"
                            />{interfaceCode}</label>
                    </div>
                    <div className='label-text'>{interfaceDescription}</div>
                </div>
            </div>
        );
    }

    const KitSaniChoice = ({ }) => {
        return (
            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h3>SISTEMA DI SANIFICAZIONE IMPIANTO</h3>
                <div className='switch-group' style={windowWidth < 1200 ? { flexDirection: 'column', marginBottom: '50px' } : { marginBottom: '50px' }}>
                    <div className='switch-option' onClick={handleFlgKitSaniChange}>
                        <img
                            effect='blur'
                            src={productModel.portataMax < 1600 ? require(`../src/images/KIT-SANI1.jpg`) : require(`../src/images/KIT-SANI2.jpg`)}
                            alt="KIT-SANI"
                            className="label-image"
                        />
                        <div className="label-container" style={{ marginTop: '10px' }}>
                            <label className="label-text-big">
                                VUOI INCLUDERE IL KIT DI SANIFICAZIONE NELL'OFFERTA?</label>
                        </div>
                        <Switch onChange={handleFlgKitSaniChange} checked={flgKitSani} className='switch'
                            offColor='#a6a6a6' onColor='#0054a0'
                        />
                    </div>
                    <div className="guida-utente" style={windowWidth > 1200 ? { maxWidth: '450px', textAlign: 'center' } : { textAlign: 'center' }}>
                        <h3>KIT DI SANIFICAZIONE</h3>
                        <p>
                            Grazie alla tecnologia di sanificazione DUST FREE è possibile sanificare le condotte aerauliche e gli ambienti.
                        </p>
                        <p>
                            I sistemi attivi, tramite la PCO sono in grado di eliminare virus, muffe e batteri migliorando la qualità dell’aria degli ambienti trattati.
                        </p>
                        <p>
                            Il dispositivo di sanificazione è da posizionare sul plenum di mandata della macchina.
                        </p>
                        <p>
                            Per maggiori informazioni consulta <a style={{ fontWeight: 'bold' }} href="https://www.aircontrolclima.it/it/c/11/sanificazione-attiva.html" target="_blank">il nostro sito</a> o contatta l’ufficio tecnico.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    const PavimentoRadianteChoice = ({ }) => {
        return (
            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h3>OPZIONE PAVIMENTO RADIANTE</h3>
                <div className='switch-group' >
                    <div className='switch-option'>
                        <div className='switch-option' onClick={handleChangePavRadiante}>
                            <img
                                effect='blur'
                                src={require(pathImmagini + "opzione_pavimento_radiante.jpg")}
                                alt="PAVIMENTO RADIANTE"
                                className="label-image"
                            />
                            <div className="label-container" style={{ flexDirection: 'column' }}>
                                <div className='guida-utente'>Tramite il sistema KOOLVOVA è possibile controllare il pavimento radiante utilizzando gli stessi comandi ( ETERNAL E SMART) dell'impianto canalizzato.</div>
                                <label className="label-text-big">
                                    VUOI AGGIUNGERE IL MODULO DEL SISTEMA RADIANTE?</label>
                            </div>
                            <Switch onChange={handleChangePavRadiante} checked={flgPavimentoRadiante} className='switch'
                                offColor='#a6a6a6' onColor='#0054a0'
                            />
                        </div>
                        {
                            flgPavimentoRadiante &&
                            <div className='select-group' style={{ flexDirection: 'column', marginBottom: '150px' }}>
                                <label className='select-container multi-select' style={{ maxWidth: '450px' }}>
                                    SELEZIONA IL NUMERO DI TERMOSTATI CORRISPONDENTI AI LOCALI DA GESTIRE CON L’IMPIANTO RADIANTE
                                    <Select
                                        className='select-item'
                                        options={numberOfZonesPavRadianteOptions}
                                        value={{ value: numLocaliPavRadiante, label: numLocaliPavRadiante + ' LOCALI' }}
                                        defaultValue={{ value: numLocaliPavRadiante, label: numLocaliPavRadiante + ' LOCALI' }}
                                        onChange={handleNumLocaliPavRadianteChange}
                                        isSearchable={false}
                                        placeholder="Seleziona il numero di locali"
                                    />
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }


    //RENDER PRINCIPALE CONFIGURATORE
    const renderStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <div className="configurator-step">
                        <h3 style={{ textAlign: 'center' }}>SELEZIONA LA TIPOLOGIA DI IMPIANTO</h3>
                        <div className='radio-group'>
                            <div className={tipoSistema === 'canalizzato' ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                onClick={handleTipoSistemaChange.bind(this, "canalizzato")}>
                                <div className='option-image-container'>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={sistemaCanalizzatoImg}
                                        alt="Sistema canalizzato"
                                        className="label-image-big"
                                        delayMethod='debounce'
                                    />
                                </div>
                                <div className="label-container">
                                    <label className="title-label">
                                        <input
                                            type="radio"
                                            name="tipoSistema"
                                            value="canalizzato"
                                            checked={tipoSistema === 'canalizzato'}
                                            onChange={handleTipoSistemaChange.bind(this, "canalizzato")}
                                            className="input-radio"
                                        />
                                        IMPIANTO CANALIZZATO</label>
                                </div>
                            </div>
                            <div className={tipoSistema === 'radiante' ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                onClick={handleTipoSistemaChange.bind(this, "radiante")}>
                                <div className='option-image-container'>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={sistemaRadianteImg}
                                        alt="Impianto radiante"
                                        className="label-image-big"
                                    />
                                </div>
                                <div className="label-container">
                                    <label className="title-label">
                                        <input
                                            type="radio"
                                            name="tipoSistema"
                                            value="radiante"
                                            checked={tipoSistema === 'radiante'}
                                            onChange={handleTipoSistemaChange.bind(this, "radiante")}
                                            className="input-radio"
                                        />
                                        SISTEMA DI CONTROLLO PER L’IMPIANTO RADIANTE</label>
                                </div>
                            </div>
                            <div className={tipoSistema === 'misto' ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                onClick={handleTipoSistemaChange.bind(this, "misto")}>
                                <div className='option-image-container'>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={sistemaMistoImg}
                                        alt="Impianti misti"
                                        className="label-image-big"
                                    />
                                </div>
                                <div className="label-container">
                                    <label className="title-label">
                                        <input
                                            type="radio"
                                            name="tipoSistema"
                                            value="misto"
                                            checked={tipoSistema === 'misto'}
                                            onChange={handleTipoSistemaChange.bind(this, "misto")}
                                            className="input-radio"
                                        />
                                        IMPIANTO MISTO – IMPIANTO CANALIZZATO E IMPIANTO DI CONTROLLO PER L’IMPIANTO RADIANTE</label>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="configurator-step">
                        <h3 style={{ textAlign: 'center' }}>CONFIGURA IL TUO KIT PER IMPIANTI DI CLIMATIZZAZIONE CANALIZZATI</h3>
                        <div className='radio-group'>
                            {
                                tipoSistema == 'canalizzato' &&
                                <div className={kitCategory === 'kitDistribuzione' ? 'radio-option elevation-2' : 'radio-option'}
                                    onClick={handleKitCategoryChange.bind(this, "kitDistribuzione")}>
                                    <div className='option-image-container'>
                                        <LazyLoadImage
                                            effect='blur'
                                            src={distribuzioneImg}
                                            alt="Kit Distribuzione"
                                            className="label-image"
                                        />
                                    </div>
                                    <div className="label-container">
                                        <label className="title-label">
                                            <input
                                                type="radio"
                                                name="kitCategory"
                                                value="kitDistribuzione"
                                                checked={kitCategory === 'kitDistribuzione'}
                                                onChange={handleKitCategoryChange.bind(this, "kitDistribuzione")}
                                                className="input-radio"
                                            />SOLO ELEMENTI DI DISTRIBUZIONE AERAULICA </label>
                                    </div>
                                </div>
                            }
                            <div className={kitCategory === 'kitMotorizzato' && !flgKitCompleto ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                onClick={handleKitCategoryChange.bind(this, "kitMotorizzato")}>
                                <div className='option-image-container'>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={motorizzatiImg}
                                        alt="Kit Motorizzato"
                                        className="label-image"
                                    />
                                </div>
                                <div className="label-container">
                                    <label className="title-label">
                                        <input
                                            type="radio"
                                            name="kitCategory"
                                            value="kitMotorizzato"
                                            checked={kitCategory === 'kitMotorizzato' && !flgKitCompleto}
                                            onChange={handleKitCategoryChange.bind(this, "kitMotorizzato")}
                                            className="input-radio"
                                        />
                                        PLENUM CABLATO o ELEMENTI MOTORIZZATI SCIOLTI</label>
                                </div>
                            </div>
                            <div className={kitCategory === 'kitMotorizzato' && flgKitCompleto ? 'radio-option elevation-2' : 'radio-option'}
                                onClick={handleKitCategoryChange.bind(this, "kitCompleto")}>
                                <div className='option-image-container'>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={motorizzatiDistribuzioneImg}
                                        alt="Kit Completo"
                                        className="label-image"
                                    />
                                </div>
                                <div className="label-container">
                                    <label className="title-label">
                                        <input
                                            type="radio"
                                            name="kitCategory"
                                            value="kitCompleto"
                                            checked={kitCategory === 'kitMotorizzato' && flgKitCompleto}
                                            onChange={handleKitCategoryChange.bind(this, "kitCompleto")}
                                            className="input-radio"
                                        />SISTEMA COMPLETO<br/>DISTRIBUZIONE AERAULICA + PLENUM CABLATO o ELEMENTI MOTORIZZATI SCIOLTI </label>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="configurator-step">
                        <h3>SELEZIONA MARCA E MODELLO DELLA MACCHINA CALANALIZZATA</h3>
                        <div className='select-group' style={windowWidth < 1200 ? { flexDirection: 'column' } : null}>
                            <label className='select-container'>
                                Marca
                                <Select
                                    className='select-item'
                                    options={brandOptions}
                                    value={productBrand}
                                    onChange={handleBrandChange}
                                    isSearchable
                                    placeholder="Seleziona la marca"
                                />
                            </label>
                            <div style={{ width: "50px" }}></div>
                            <label className='select-container'>
                                Modello
                                <Select
                                    className='select-item'
                                    options={modelOptions}
                                    value={productModel}
                                    onChange={handleModelChange}
                                    isSearchable
                                    placeholder="Seleziona il modello"
                                    isDisabled={!flgModelEnabled}
                                />
                            </label>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="configurator-step">
                        <h3>SELEZIONA IL NUMERO DI LOCALI DA CONTROLLARE </h3>
                        <div className='select-group' style={{ flexDirection: 'column' }}>
                            <label className='select-container multi-select'>
                                NUMERO DI LOCALI
                                <Select
                                    className='select-item'
                                    options={numberOfZonesOptions}
                                    value={{ value: numberOfZones, label: numberOfZones + ' LOCALI' }}
                                    defaultValue={{ value: numberOfZones, label: numberOfZones + ' LOCALI' }}
                                    onChange={handleNumberOfZonesChange}
                                    isSearchable={false}
                                    placeholder="Seleziona la marca"
                                    isDisabled={flgConf2}
                                />
                            </label>
                            {
                                tipoSistema == 'canalizzato' || tipoSistema == 'misto' ?
                                    <SelezioneStacchi numberOfZones={numberOfZones} codicePlenum={productModel.plenum} windowWidth={windowWidth} />
                                    : null
                            }
                            {
                                productBrand.value == 'NON DEFINITA' && tipoSistema != 'radiante' &&
                                <div className='select-group' style={{ marginBottom: '50px' }}>
                                    <label className='select-container'>
                                        DIAMETRO DEGLI STACCHI
                                        <Select
                                            className='select-item'
                                            options={diametroStacchiOptions}
                                            value={diametroStacchi}
                                            onChange={handleDimCanottiChange}
                                            isSearchable
                                            placeholder="Seleziona il diametro degli stacchi"
                                        />
                                    </label>
                                    <div style={{ width: "50px" }}></div>
                                    <div className='select-container'></div>
                                </div>
                            }
                        </div>
                    </div >
                );
            case 4:
                return (
                    <div className="configurator-step">
                        {
                            tipoSistema == 'canalizzato' || tipoSistema == 'misto' ?
                                <PlenumSiNoChoice />
                                :
                                <div>
                                    <h3>SELEZIONA LA TIPOLOGIA DI TERMOSTATI E LA COLORAZIONE</h3>
                                    <div className='radio-group' >
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginLeft: '75px' }}
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"bianchi"} impiantoType={"RADIO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_RADIO.jpg")}
                                                alt={"ex"}
                                                className="option-middle-image"
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"neri"} impiantoType={"RADIO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginRight: '75px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='radio-group' >
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginLeft: '75px' }}
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"bianchi"} impiantoType={"FILO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_FILO.jpg")}
                                                alt={"ex"}
                                                className="option-middle-image"
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"neri"} impiantoType={"FILO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginRight: '75px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="guida-utente">
                                        <p>
                                            Il sistema prevede sempre un termostato per locale per la gestione delle temperature. Il KIT si compone di un comando MASTER (ETERNAL) con funzione di centralizzatore e i comandi SLAVE (SMART) .
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                );
            case 5:
                return (
                    <div className="configurator-step">
                        {
                            kitCategory == 'kitDistribuzione' ?
                                <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3>SELEZIONA L'ESTETICA DEL TERMINALE</h3>
                                    {
                                        alertPortataTerminale &&
                                        <div className="alert-container">
                                            <div className='alert-text'>
                                                <div>
                                                    <IoWarning className="text-icon-left" />
                                                </div>
                                                La portata d’aria nominale del singolo terminale non soddisfa i parametri in relazione alla portata massima della macchina selezionata.
                                                È comunque possibile proseguire con la selezione. Per maggiori informazioni contattare l’ufficio tecnico.
                                                <div>
                                                    <IoWarning className="text-icon-right" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <TerminalSelection />
                                </div>
                                :
                                <div>
                                    <h3>SELEZIONA LA CONFIGURAZIONE DEGLI ELEMENTI MOTORIZZATI</h3>
                                    <div className='radio-group' >
                                        <div className={flgPCAB == 1 ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                            onClick={handleFlgPCABChange.bind(this, 1)}>
                                            <div className='option-image-container'>
                                                <LazyLoadImage
                                                    effect='blur'
                                                    src={require(`../src/images/immagini-articoli/plenum_cablato_${numTotaleStacchi}zone.png`)}
                                                    alt="plenum-cablato"
                                                    className="label-image"
                                                />
                                            </div>
                                            <div className="label-container">
                                                <label className="title-label">
                                                    <input
                                                        type="radio"
                                                        name="pcabSiNo"
                                                        value="cablato"
                                                        checked={flgPCAB == 1}
                                                        onChange={handleFlgPCABChange.bind(this, 1)}
                                                        className="input-radio"
                                                    />PLENUM CABLATO CON ELEMENTI MOTORIZZATI</label>
                                            </div>
                                        </div>
                                        <div className={flgPCAB == 2 ? 'radio-option first-option elevation-2' : 'radio-option first-option'}
                                            onClick={handleFlgPCABChange.bind(this, 2)}>
                                            <div className='option-image-container'>
                                                <LazyLoadImage
                                                    effect='blur'
                                                    src={require(pathImmagini + "componenti_sciolti.png")}
                                                    alt="plenum-cablato"
                                                    className="label-image"
                                                />
                                            </div>
                                            <div className="label-container">
                                                <label className="title-label">
                                                    <input
                                                        type="radio"
                                                        name="pcabSiNo"
                                                        value="non cablato"
                                                        checked={flgPCAB == 2}
                                                        onChange={handleFlgPCABChange.bind(this, 2)}
                                                        className="input-radio"
                                                    />ELEMENTI MOTORIZZATI NON CABLATI</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                );
            case 6:
                return (
                    <div className="configurator-step">
                        {
                            kitCategory == 'kitDistribuzione' && !flgConf2 ? //se sto facendo il distribuzione mi chiede anche se voglio il KIT SANI ma solo se non lo ha già chiesto in precedenza per kitMotorizzato
                                <KitSaniChoice />
                                :
                                <div>
                                    <h3>SELEZIONA LA TIPOLOGIA DI TERMOSTATI E LA COLORAZIONE</h3>
                                    <div className='radio-group' >
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginLeft: '75px' }}
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"bianchi"} impiantoType={"RADIO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_RADIO.jpg")}
                                                alt={"ex"}
                                                className="option-middle-image"
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"neri"} impiantoType={"RADIO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginRight: '75px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='radio-group' >
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginLeft: '75px' }}
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"bianchi"} impiantoType={"FILO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_FILO.jpg")}
                                                alt={"ex"}
                                                className="option-middle-image"
                                            />
                                        </div>
                                        <ComandiChoice commandColor={"neri"} impiantoType={"FILO"} />
                                        <div className='secondary-image-container'>
                                            <img
                                                effect='blur'
                                                src={require(pathImmagini + "ICONA_APP.jpg")}
                                                alt={"ex"}
                                                className="secondary-image"
                                                style={{ marginRight: '75px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="guida-utente">
                                        <p>
                                            Il sistema prevede sempre un termostato per locale per la gestione delle temperature. Il KIT si compone di un comando MASTER (ETERNAL) con funzione di centralizzatore e i comandi SLAVE (SMART) .
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                );
            case 7:
                return (
                    <div className="configurator-step">
                        {
                            kitCategory == 'kitDistribuzione' ?
                                null
                                :
                                <div>
                                    <h3>SELEZIONA IL TIPO DI INTERFACCIA</h3>
                                    <div className='radio-group' >
                                        <div className="guida-utente radio-option" style={{ textAlign: 'center' }}>
                                            <h3>GUIDA ALLA SELEZIONE DELL'INTERFACCIA</h3>
                                            {
                                                productModel.interfacciaFilo != '' || productModel.interfacciaIR != '' ?
                                                    <div>
                                                        <p>
                                                            L’interfaccia di comunicazione permette al sistema di dialogare direttamente con l’unità interna canalizzata, gestendone l’accensione e lo spegnimento, il cambio ESTATE-INVERNO e regolandone le velocità in base alle necessità dell’impianto.
                                                        </p>
                                                        <p>
                                                            L’INTERFACCIA FILO dialoga con il comando a FILO della macchina interna canalizzata.
                                                        </p>
                                                        <p>
                                                            L’INTERFACCIA INFRAROSSI dialoga con il ricevitore INFRAROSSI  della macchina interna canalizzata (KIT TELECOMANDO) .
                                                        </p>
                                                    </div>
                                                    : productModel.interfacciaIdro != '' ?
                                                        <div>
                                                            <p>
                                                                L’interfaccia di comunicazione permette al sistema di dialogare direttamente con l’unità interna canalizzata, gestendone l’accensione e lo spegnimento, il cambio ESTATE-INVERNO e regolandone le velocità in base alle necessità dell’impianto.
                                                            </p>
                                                        </div>
                                                        : null
                                            }
                                        </div>
                                        {
                                            productModel.interfacciaFilo != '' ?
                                                <TipoInterfacciaChoice interfaceType="FILO" interfaceCode="CFI" interfaceDescription="INTERFACCIA FILO" />
                                                : null
                                        }
                                        {
                                            productModel.interfacciaIR != '' ?
                                                <TipoInterfacciaChoice interfaceType="INFRAROSSO" interfaceCode="IR" interfaceDescription="INTERFACCIA INFRAROSSI" />
                                                : null
                                    /*SE SI VUOLE DIVIDERE IN DUE RIGHE METTERE LE DUE RIGHE SEGUENTI *DOPO* LA PARENTESI } QUI SOTTO
                                </div>
                                    <div className='radio-group' >*/}
                                        {
                                            productModel.interfacciaIdro != '' ?
                                                <TipoInterfacciaChoice interfaceType="FANCOIL" interfaceCode="GTP" interfaceDescription="INTERFACCIA FANCOIL" />
                                                : null
                                        }
                                        {
                                            productModel.value == 'NON DEFINITO' && false ?
                                                <TipoInterfacciaChoice interfaceType="NO" interfaceCode="NO INT" interfaceDescription="No interfaccia" />
                                                : null
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                );
            case 8:
                return (
                    <div className="configurator-step">
                        {
                            kitCategory == 'kitDistribuzione' ?
                                null //fine
                                :
                                <PavimentoRadianteChoice />
                        }
                    </div>
                );
            case 9:
                return (
                    <div className="configurator-step">
                        {
                            kitCategory == 'kitDistribuzione' ?
                                null //fine
                                : !flgConf2 ? //se l'ho già chiesto per distribuzione non ripeto
                                    <KitSaniChoice />
                                    : null
                        }
                    </div>
                );
            case 100:
                return (
                    <form onSubmit={handleSubmitForm} className='contanct-form'>
                        <h3>INFORMAZIONI DI CONTATTO</h3>
                        {
                            false &&
                            codiciKIT.map((codice, index) => (
                                <h3 key={index} className='codice-kit'>{codice}</h3>
                            ))
                        }
                        {
                            false && flgPavimentoRadiante &&
                            <h3 className='codice-kit'>NH-SR-UNI</h3>
                        }
                        {
                            false && tipoSistema == 'radiante' &&
                            <h3 className='codice-kit'>KN-UNI-WIFI</h3>
                        }
                        {
                            false && configuratoreCtx.flgPlenum && flgPCAB != 1 &&
                            <h3 className='codice-kit'>{codicePlenum}</h3>
                        }
                        <label className={ragioneSocialeError ? "input-container form-input label-error" : "input-container form-input"} style={{ marginTop: "25px" }}>
                            Ragione sociale
                            <input
                                type="text"
                                value={ragioneSociale}
                                className={ragioneSocialeError ? 'input-item error' : 'input-item'}
                                onChange={(e) => {
                                    setRagioneSociale(e.target.value);
                                    setRagioneSocialeError(false);
                                }}
                                maxLength={70}
                            />
                        </label>

                        <label className={emailError ? "input-container form-input label-error" : "input-container form-input"} >
                            Indirizzo email
                            <input
                                type="email"
                                value={email}
                                className={emailError ? 'input-item error' : 'input-item'}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError(false);
                                }}
                                maxLength={200}
                            />
                        </label>

                        <label className={telefonoError ? "input-container form-input label-error" : "input-container form-input"} >
                            Contatto telefonico
                            <input
                                type="tel"
                                value={telefono}
                                className={telefonoError ? 'input-item error' : 'input-item'}
                                onChange={(e) => {
                                    setTelefono(e.target.value);
                                    setTelefonoError(false);
                                }}
                                maxLength={50}
                            />
                        </label>

                        {
                            false &&
                            <button
                                className="button"
                                onClick={geolocalizzazione}
                            >
                                Usa la mia posizione <IoCheckmarkCircle className="text-icon-right" />
                            </button>
                        }

                        <label className={regioneError ? "input-container form-input label-error" : "input-container form-input"} >
                            Regione
                            <Select
                                className='select-item'
                                options={regioniOptions}
                                value={regione}
                                onChange={handleRegioneChange}
                                isSearchable
                                placeholder="Seleziona la regione"
                                styles={regioneError ? selectErrorStyle : {}}
                            />
                        </label>

                        <label className={provinciaError ? "input-container form-input label-error" : "input-container form-input"} >
                            Provincia
                            <Select
                                className={provinciaError ? 'select-item error' : 'select-item'}
                                options={provinceOptions}
                                value={provincia}
                                onChange={handleProvinciaChange}
                                isSearchable
                                isDisabled={!flgProvinceEnabled}
                                placeholder="Seleziona la provincia"
                                styles={provinciaError ? selectErrorStyle : {}}
                            />
                        </label>

                        <div className="checkbox-container form-input">
                            <input type="checkbox" className='checkbox' checked={flgPrivacy} onChange={handlePrivacyChange} required />
                            Ho letto e accetto l'<a href="https://www.aircontrolclima.it/it/pc/3/privacy.html" target="_blank">informativa sulla privacy</a>
                        </div>

                        <div className='form-input' style={{ marginTop: '15px' }}>
                            <ReCAPTCHA
                                sitekey="6LeVi50mAAAAAGyGhZp1s2rKZMc2yLx6Fs4t_pv7"
                                onChange={handleCaptchaChange}
                            />
                        </div>

                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <div className='configurator' ref={scrollableElementRef} >
            <Navbar bg="primary" variant="dark" fixed="top" className="navbar">
                <Container>
                    <Navbar.Brand href="https://www.aircontrolclima.it/" target="_blank">
                        <LazyLoadImage
                            effect='blur'
                            src={logo}
                            alt="Logo"
                            className="logo-image"
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <div className="configurator-wrapper">
                {
                    step > 1 && step < 99 ?
                        <div className='progress-bar-container'>
                            <ProgressBar step={step} totalSteps={totalSteps} handleNext={handleNextStep}
                                handlePrevious={handlePreviousStep} flgConf2={flgConf2} kitCategory={kitCategory} />
                        </div>
                        : <div style={{ height: '20px' }} ></div>
                }
                <div className="configurator-container">
                    {
                        !isLoading ?
                            <div className="configurator-box">
                                {renderStepContent()}
                                <div className="buttons">
                                    <button
                                        className={step == 0 || (step < 6 && flgConf2 && kitCategory == 'kitMotorizzato')
                                            || (step < 6 && flgConf2 && kitCategory == 'kitDistribuzione') ?
                                            "button left invisible" : "button left"}
                                        onClick={step == 0 || (step < 6 && flgConf2 && kitCategory == 'kitMotorizzato')
                                            || (step < 6 && flgConf2 && kitCategory == 'kitDistribuzione') ?
                                            null : handlePreviousStep}>
                                        <BsArrowLeftShort className="text-icon-left" />
                                        Indietro
                                    </button>
                                    <button
                                        className="button right"
                                        onClick={handleNextStep}
                                    >
                                        {(tipoSistema == 'radiante' && step == totalStepRad)
                                            || ((kitCategory == 'kitDistribuzione' && ((step == totalStepDis && !flgConf2) || (step == totalStepDis - 1 && flgConf2)))
                                                || (kitCategory == 'kitMotorizzato' && ((step == totalStepMot && !flgConf2) || (step == totalStepMot - 2 && flgConf2)))) ?
                                            'Fine' : (step != 100) ? 'Avanti' : 'Invia preventivo'}
                                        {(tipoSistema == 'radiante' && step == totalStepRad)
                                            || ((kitCategory == 'kitDistribuzione' && ((step == totalStepDis && !flgConf2) || (step == totalStepDis - 1 && flgConf2)))
                                                || (kitCategory == 'kitMotorizzato' && ((step == totalStepMot && !flgConf2) || (step == totalStepMot - 2 && flgConf2)))) ?
                                            <BsCheckLg className="text-icon-right" />
                                            : (step != 100) ? <BsArrowRightShort className="text-icon-right" />
                                                : <IoMail className="text-icon-right" />}
                                    </button>
                                </div>
                            </div>
                            :
                            <MyLoader active={true} color="#0054a0" />
                    }
                </div>
                {
                    step > 3 && tipoImpianto != "" ?
                        <div className="configurator-container">
                            <div className="configurator-box">
                                {
                                    tipoSistema == 'radiante' ?
                                        <h3>IL TUO SISTEMA DI CONTROLLO PER L’IMPIANTO RADIANTE</h3>
                                        :
                                        <h3>IL TUO IMPIANTO
                                            {tipoImpianto == 'RADIO' ? ' RADIO' : tipoImpianto == 'FILO' ? ' FILO' : ''}
                                            {kitCategory == 'kitDistribuzione' ? ' DISTRIBUZIONE' : ' MOTORIZZATO'}
                                            {flgPavimentoRadiante ? ' CON PAVIMENTO RADIANTE' : ''}
                                        </h3>
                                }
                                {
                                    step >= 4 && kitCategory == 'kitMotorizzato' || (kitCategory == 'kitDistribuzione' && flgConf2) ?
                                        <Canvas
                                            ref={canvasRef}
                                            width={windowWidth < 800 ? 300 : windowWidth < 1200 ? 600 : windowWidth < 1400 ? 1000 : windowWidth < 1700 ? 1200 : windowWidth < 2000 ? 1400 : 1600}
                                            step={step}
                                            coloreComandi={coloreComandi}
                                            tipoSistema={tipoSistema}
                                            tipoImpianto={tipoImpianto}
                                            numberOfZones={numberOfZones}
                                            numTotaleStacchi={numTotaleStacchi}
                                            numLocaliPavRadiante={numLocaliPavRadiante}
                                            tipoInterfaccia={tipoInterfaccia}
                                            flgPavimentoRadiante={flgPavimentoRadiante}
                                            portataMax={productModel.portataMax}
                                            flgKitSani={flgKitSani} />
                                        :
                                        tipoSistema == 'radiante' ?
                                            step == 4 || coloreComandi == "" ?
                                                <label className='select-image-container'>
                                                    <LazyLoadImage
                                                        effect='blur'
                                                        src={require(`${pathImmagini}SCHEMI-IMPIANTO/collegamento_${tipoImpianto}_2zone_2zone_impianto${tipoImpianto}_centralina_solo_PAV-RADIANTE.jpg`)}
                                                        alt="Anteprima Kit"
                                                        className="anteprima-image"
                                                    />
                                                </label>
                                                : step == 5 || tipoInterfaccia == "" ?
                                                    <label className='select-image-container'>
                                                        <LazyLoadImage
                                                            effect='blur'
                                                            src={require(`${pathImmagini}SCHEMI-IMPIANTO/collegamento_${tipoImpianto}_${numberOfZones}zone_${numberOfZones}zone_impianto${tipoImpianto}_comandi_${coloreComandi}_solo_PAV-RADIANTE.jpg`)}
                                                            alt="Anteprima Kit"
                                                            className="anteprima-image"
                                                        />
                                                    </label>
                                                    : null
                                            : null
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
            <footer>
                <div className='footer-text-container'>
                    <label className='footer-text'>
                        2023 Air Control Srl - P.Iva 04805320969 - R.E.A. di Milano n. 1773730 - www.aircontrolclima.it - info@aircontrolclima.it
                    </label>
                </div>
            </footer>
            <Modal
                isOpen={modalError.flgModal}
                onRequestClose={() => setModalError({ flgModal: false, titolo: "", testo: "" })}
                className="error-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={() => setModalError({ flgModal: false, titolo: "", testo: "" })}
                    />
                </div>
                <div className="error-modal-title">
                    {modalError.titolo} <IoWarning className="text-icon-right" />
                </div>
                <div>{modalError.testo}</div>
            </Modal>
            <Modal
                isOpen={modalConferma.flgModal}
                onRequestClose={closeModalConferma}
                className="info-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <IoClose
                        className="close-icon"
                        onClick={closeModalConferma}
                    />
                </div>
                <div className="info-modal-title">{modalConferma.titolo}<IoMail className="text-icon-right" /></div>
                <div>{modalConferma.testo}</div>
            </Modal>
            <Modal
                isOpen={modalConf2.flgModal}
                onRequestClose={() => setModalConf2({ flgModal: false, titolo: '', testo: '' })}
                className="info-modal"
                contentLabel="Example Modal"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cambia l'opacità per aumentare l'oscuramento
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div className="info-modal-title">{modalConf2.titolo}<IoCheckmarkCircle className="text-icon-right" /></div>
                <div style={{ marginBottom: '40px' }}>
                    {
                        kitCategory == 'kitDistribuzione' ?
                            <div>
                                <p>
                                    La configurazione degli elementi di distribuzione aeraulica è stata completata.
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                    QUALI SONO I VANTAGGI DEL SISTEMA MOTORIZZATO?
                                </p>
                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', marginBottom: '5px' }}>
                                    <div><FaThermometerThreeQuarters className="text-icon-left" style={{ color: '#86b0d6' }} /></div>
                                    Gestione indipendente delle temperature nei singoli ambienti con una sola unità interna a vantaggio del comfort termico.
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', marginBottom: '5px' }}>
                                    <div><FaLightbulb className="text-icon-left" style={{ color: '#e3b200' }} /></div>
                                    Gestione intelligente della climatizzazione tramite programmazione.
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', marginBottom: '5px' }}>
                                    <div><FaMobile className="text-icon-left" /></div>
                                    Gestione del sistema di climatizzazione da remoto tramite APP di serie.
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', marginBottom: '5px' }}>
                                    <div><FaLeaf className="text-icon-left" style={{ color: '#1bc246' }} /></div>
                                    Risparmio energetico.
                                </div>
                                <p>Per maggiori informazioni consulta <a style={{ fontWeight: 'bold' }} href="https://www.aircontrolclima.it/it/c/1/zonificazione-koolnova.html" target="_blank">il nostro sito</a> o contatta l’ufficio tecnico.</p>
                                <p>
                                    Vuoi aggiungere al sistema anche gli elementi motorizzati?
                                </p>
                            </div>
                            :
                            <div>
                                <p>
                                    La configurazione del plenum cablato / elementi motorizzati è stata completata.
                                </p>
                                <p>
                                    Configurare anche la distribuzione aeraulica?
                                </p>
                            </div>
                    }
                </div>
                <div className="buttons">
                    <button
                        style={{margin: '5px'}}
                        className="button red"
                        onClick={handleCloseConfigurazione2}>
                        NO NON VOGLIO INCLUDERE ANCHE GLI ELEMENTI MOTORIZZATI <div><IoCloseCircleSharp className="text-icon-right" /></div>
                    </button>
                    <button
                        style={{margin: '5px'}}
                        className="button green"
                        onClick={handleConfigurazione2}
                    >
                        SI VOGLIO INCLUDERE ANCHE GLI ELEMENTI MOTORIZZATI <div><IoCheckmarkCircle className="text-icon-right" /></div>
                    </button>
                </div>
            </Modal>
        </div >
    );
};

export default Configurator;

